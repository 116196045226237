import DashboardLayout from "components/MDComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/MDComponents/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";

/**
 * TODO:
 * - Simple about me and way to navigate to portfolio
 * - Project list and navigation
 * - Tools list and navigation
 * - Applications list and navigation
 *
 */
function Home() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    // Fetch the JSON file
    fetch('./items.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to load JSON file');
        }
        return response.json();
      })
      .then((data) => setItems(data.items))
      .catch((error) => console.error('Error fetching JSON:', error));
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar title="Home" />
      <Grid container spacing={3}>
        <Grid size={12}>
          <Card>
            Home
          </Card>
        </Grid>

        <Grid size={{ xs: 12, md: 6, xl: 3 }}>
          <Card>
            News
          </Card>
        </Grid>
        <Grid size={{ xs: 12, md: 6, xl: 3 }}>
          <Card>
            Projects
          </Card>
        </Grid>
        <Grid size={{ xs: 12, md: 6, xl: 3 }}>
          <Card>
            Tools
          </Card>
        </Grid>
        <Grid size={{ xs: 12, md: 6, xl: 3 }}>
          <Card>
            Applications
          </Card>
        </Grid>
      </Grid>

      <div style={{ display: 'flex', gap: '10px' }}>
        {items.map((item, index) => (
          <div
            key={index}
            style={{
              width: '100px',
              height: '100px',
              backgroundColor: item.color,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              fontWeight: 'bold',
              borderRadius: '8px',
            }}
          >
            {item.name}
          </div>
        ))}
      </div>
    </DashboardLayout>
  )
}

export default Home;