/**
 * The base colors for the MD React.
 * You can add new color using this file.
 * You can customized the colors for the entire MD React using thie file.
 */

const colors = {
  background: {
    default: "#121212",
    sidenav: "#181818",
    card: "#1e1e1e",
  },

  text: {
    main: "#ffffffcc",
    focus: "#ffffffcc",
  },

  transparent: {
    main: "#00000000",
  },

  white: {
    main: "#ffffff",
    focus: "#ffffff",
  },

  black: {
    light: "#000000",
    main: "#000000",
    focus: "#000000",
  },

  primary: {
    main: "#8a7e42",
    focus: "#423509",
  },

  secondary: {
    main: "#7b809a",
    focus: "#8f93a9",
  },

  info: {
    main: "#0077b5",
    focus: "#00669c",
  },

  success: {
    main: "#5ca55f",
    focus: "#38853b",
  },

  warning: {
    main: "#f4a42e",
    focus: "#ec8305",
  },

  error: {
    main: "#e52d27",
    focus: "#b21d22",
  },

  light: {
    main: "#f0f2f566",
    focus: "#f0f2f566",
  },

  dark: {
    main: "#42424a",
    focus: "#191919",
  },

  grey: {
    100: "#f8f9fa",
    200: "#f0f2f5",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#6c757d",
    700: "#495057",
    800: "#343a40",
    900: "#212529",
  },

  gradients: {
    primary: {
      main: "#8a7e42",
      state: "#423509",
    },

    secondary: {
      main: "#747b8a",
      state: "#333a43",
    },

    info: {
      main: "#0f90d3",
      state: "#00669c",
    },

    success: {
      main: "#5ca55f",
      state: "#38853b",
    },

    warning: {
      main: "#f4a42e",
      state: "#cd7204",
    },

    error: {
      main: "#e52d27",
      state: "#b21d22",
    },

    light: {
      main: "#EBEFF4",
      state: "#CED4DA",
    },

    dark: {
      main: "#1f1f1f",
      state: "#101010",
    },
  },

  badgeColors: {
    primary: {
      background: "#b1a97c",
      text: "#423509",
    },

    secondary: {
      background: "#d7d9e1",
      text: "#495361",
    },

    info: {
      background: "#a0c8dc",
      text: "#00669c",
    },

    success: {
      background: "#a5e3a8",
      text: "#38853b",
    },

    warning: {
      background: "#f3d2a1",
      text: "#cd7204",
    },

    error: {
      background: "#e4b2b0",
      text: "#d41f1a",
    },

    light: {
      background: "#ffffff",
      text: "#9ca3aa",
    },

    dark: {
      background: "#676666",
      text: "#101010",
    },
  },

  coloredShadows: {
    primary: "#988d53",
    secondary: "#404953",
    info: "#248ec5",
    success: "#8dc690",
    warning: "#efb052",
    error: "#ef4a45",
    light: "#adb5bd",
    dark: "#676666",
  },

  inputBorderColor: "#d2d6da",

  tabs: {
    indicator: { boxShadow: "#ddd" },
  },

  icon: {
    main: "#ffffffcc",
    focus: "#ffffffcc"
  }

};

export default colors;
