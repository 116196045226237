import colors from "assets/theme-dark/base/colors";

const listItemIcon = {
  styleOverrides: {
    root:{
      color: colors.text.main
    }
  }
};

export default listItemIcon;
