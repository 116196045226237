import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";
import MDInput from "components/MDBase/MDInput";
import MDButton from "components/MDBase/MDButton";
import Card from "@mui/material/Card";

function ResetPassword() {
  return (
    <Card>
      <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="success"
        mx={2}
        mt={-3}
        py={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
          Reset Password
        </MDTypography>
        <MDTypography display="block" variant="button" color="white" my={1}>
          You will receive an e-mail in maximum 60 seconds
        </MDTypography>
      </MDBox>
      <MDBox pt={4} pb={3} px={3}>
        <MDBox component="form" role="form">
          <MDBox mb={4}>
            <MDInput type="email" label="Email" variant="standard" fullWidth />
          </MDBox>
          <MDBox mt={6} mb={1}>
            <MDButton variant="gradient" color="info" fullWidth>
              reset
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  )
}

export default ResetPassword